
form[name=module-switch-form] {
  display: flex;
}
form[name=module-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=module-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=module-switch-form] > div > div {
  flex-grow:1;
}
form[name=module-switch-form] > button {
  margin-left:10px;
}


.b-table .options a[data-v-35e8d62a]:has(i) {
  font-size: 1.3em;
  margin-right: 10px;
}


.b-table .options a[data-v-4571a694]:has(i) {
  font-size: 1.3em;
  margin-right: 10px;
}

